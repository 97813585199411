@import url('https://fonts.googleapis.com/css?family=Montserrat:400');
@import url('https://fonts.googleapis.com/css?family=Montserrat:500');

* {
  font-weight: 400;
  box-sizing: border-box;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  /*  */
}

html {
  overflow: hidden;
  overscroll-behavior-y: none;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  background-color: #fff;
  height: 100vh;
  width: 100vw;
}

#root {
  height: 100vh;
  width: 100vw;
}

button,
p {
  margin: 0;
}

a {
  text-decoration: none;
  color: unset;
  display: flex;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.MuiDataGrid-toolbarContainer,
.MuiDataGrid-columnHeaders {
  background-color: rgba(0, 0, 0, 0.04);
}

.css-16siigx-MuiDataGrid-root {
  overflow: unset !important;
}

.MuiDataGrid-aggregationColumnHeaderLabel {
  display: none; /* hide SUM text in table headers */
}

.MuiSelect-select {
  display: flex !important;
  flex-direction: row !important;
}

.MuiDrawer-paper {
  overflow-y: unset !important;
}

.MuiAutocomplete-popper {
  z-index: 99;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    /* Fix IE11 wobbly */
    transform-origin: 50% 50%;
  }
  100% {
    transform: rotate(360deg);
  }
}
